import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text } from 'components/atoms';

const HighlightedQuoteText = ({ data, className }) => {
  const { content, author, text, background_fill, background_color } =
    data || {};

  const quote = author ? `${text}” - ${author}` : `${text}”`;
  const highlight = content;
  const textContent = highlight || quote;

  const backgroundColorStyle = background_fill
    ? {
        backgroundColor: `${background_color}`,
      }
    : {};

  return (
    <Section
      className={cn(
        'highlighted-text',
        { 'case-study_bg-fill': background_fill },
        [className]
      )}
      style={backgroundColorStyle}
    >
      <Container>
        <Row>
          <Column
            xl="6"
            lg="8"
            md="8"
            col="10"
            className="offset-1 offset-xl-3 offset-lg-2 offset-md-2 highlighted-text__container"
          >
            <div
              className={cn(
                'highlighted-text__content',
                (text || author) && 'highlighted-text__content--quoted'
              )}
            >
              <Text tag="p" className="highlighted-text__content__text">
                {`<span>${textContent}</span>`}
              </Text>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

HighlightedQuoteText.defaultProps = {
  className: '',
};

HighlightedQuoteText.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default HighlightedQuoteText;
